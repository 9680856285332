<template>
  <div class="app-container pb-50px">
    <el-card v-loading="loading" shadow="never">
      <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        size="small"
        label-width="120px"
      >
        <el-form-item label="SMTP地址" prop="smtp">
          <el-input
            v-model="form.smtp"
            style="width: 480px"
            placeholder="请输入SMTP服务器地址"
          ></el-input>
          <div class="form-tips">QQ邮箱的SMTP服务器地址是smtp.qq.com，163邮箱的SMTP服务器地址是smtp.163.com</div>
        </el-form-item>
        <el-form-item label="协议端口号" prop="smtp_port">
          <el-input
            v-model="form.smtp_port"
            style="width: 480px"
            placeholder="请输入端口号"
          ></el-input>
          <div class="form-tips">QQ邮箱的ssl协议方式端口号是465 / 587，163邮箱的ssl协议方式端口号是465 / 994</div>
        </el-form-item>
        <el-form-item label="邮箱账号" prop="smtp_user">
          <el-input
            v-model="form.smtp_user"
            style="width: 480px"
            placeholder="请输入邮箱用户名"
          ></el-input>
          <div class="form-tips">demo@qq.com</div>
        </el-form-item>
        <el-form-item label="邮箱密码" prop="smtp_password">
          <el-input
            type="password"
            v-model="form.smtp_password"
            style="width: 480px"
            placeholder="请输入邮箱密码"
          ></el-input>
          <div class="form-tips">不一定是登录密码，如QQ邮箱的是第三方授权登录码，要自己去开启，在邮箱的设置->账户->POP3/IMAP/SMTP/Exchange/CardDAV/CalDAV服务</div>
        </el-form-item>
        <el-form-item label="显示的发送者" prop="from">
          <el-input
            v-model="form.from"
            style="width: 480px"
            placeholder="请输入要显示的发送者"
          ></el-input>
          <div class="form-tips">用于展示给发送方，如：系统管理员</div>
        </el-form-item>
        <el-form-item label="显示的邮箱" prop="email">
          <el-input
            v-model="form.email"
            style="width: 480px"
            placeholder="请输入要显示的发送者邮箱"
          ></el-input>
          <div class="form-tips">可以不同于上面的账户，用于展示给发送方的邮箱，如：admin@qq.com</div>
        </el-form-item>
      </el-form>
    </el-card>
    <footer-wrap>
      <el-button type="primary" size="small" @click="onSubmit">
        保 存
      </el-button>
      <el-button type="primary" size="small" @click="onTry">
        发送测试邮件
      </el-button>
    </footer-wrap>

    <try-dialog ref="popupRefs"></try-dialog>
  </div>
</template>

<script>
import FooterWrap from "@/components/footer-wrap/index.vue";

import { apiGetMailer, apiSetMailer } from "@/api/setting";

import TryDialog from "./try.vue";

export default {
  components: {
    TryDialog,
    FooterWrap,
  },
  data() {
    const emailValidator = (rule, value, callback) => {
      const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      if (!regex.test(value))
        callback(new Error("请输入正确的内容"));
      callback();
    };
    return {
      loading: false,
      form: {
        smtp: "",
        smtp_port: "",
        smtp_user: "",
        smtp_password: "",
        from: "",
        email: "",
      },
      rules: {
        smtp: [
          {
            required: true,
            message: "请输入SMTP服务器地址",
            trigger: ["blur"],
          },
        ],
        smtp_port: [
          {
            required: true,
            message: "请输入端口号",
            trigger: ["blur"],
          },
        ],
        smtp_user: [
          {
            required: true,
            message: "请输入邮箱用户名",
            trigger: ["blur"],
          },
          {
            validator: emailValidator,
            trigger: "blur",
          },
        ],
        smtp_password: [
          {
            required: true,
            message: "请输入邮箱密码",
            trigger: ["blur"],
          },
        ],
        from: [
          {
            required: true,
            message: "请输入要显示的发送者",
            trigger: ["blur"],
          },
        ],
        email: [
          {
            required: true,
            message: "请输入要显示的发送者邮箱",
            trigger: ["blur"],
          },
          {
            validator: emailValidator,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getMailer();
  },
  methods: {
    getMailer() {
      this.loading = true;
      apiGetMailer().then((resp) => {
        this.form.smtp = resp.smtp;
        this.form.smtp_port = resp.smtp_port;
        this.form.smtp_user = resp.smtp_user;
        this.form.smtp_password = resp.smtp_password;
        this.form.from = resp.from;
        this.form.email = resp.email;
        this.loading = false;
      });
    },
    onTry() {
      this.$refs.popupRefs.openDialog();
    },
    onSubmit() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return;
        apiSetMailer({
          smtp: this.form.smtp,
          smtp_port: this.form.smtp_port,
          smtp_user: this.form.smtp_user,
          smtp_password: this.form.smtp_password,
          from: this.form.from,
          email: this.form.email,
        }).then(() => {
          this.$message({
            type: "success",
            message: "保存成功！",
          });
          this.getMailer();
        });
      });
    },
  },
};
</script>

<template>
  <popup
    ref="popupRefs"
    :async="true"
    width="400px"
    title="发送测试邮件"
    confirm-button="发送"
    @confirm="onConfirm"
    @close="onClose"
  >
    <el-form
      ref="formRefs"
      v-loading="loading"
      :model="form"
      :rules="rules"
      label-width="80px"
      size="small"
    >
      <el-form-item label="邮箱地址" prop="email">
        <el-input v-model="form.email" placeholder="请输入接收测试邮箱地址"></el-input>
      </el-form-item>
    </el-form>
  </popup>
</template>

<script>
import Popup from "@/components/popup/index.vue";

import { apiTryMailer } from "@/api/setting";

export default {
  components: {
    Popup,
  },
  data() {
    const emailValidator = (rule, value, callback) => {
      const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      if (!regex.test(value))
        callback(new Error("请输入正确的邮箱地址"));
      callback();
    };
    return {
      loading: false,
      form: {
        email: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "请输入邮箱地址",
            trigger: ["blur"],
          },
          {
            validator: emailValidator,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openDialog() {
      this.$refs.popupRefs.open();
    },
    closeDialog() {
      this.$refs.popupRefs.close();
    },
    onClose() {
      this.$nextTick(() => {
        this.$refs.formRefs.clearValidate();
        this.$refs.formRefs.resetFields();
      });
    },
    onConfirm() {
      this.$refs.formRefs.validate((valid) => {
        if (!valid) return;
        apiTryMailer(this.form).then(() => {
          this.closeDialog();
          this.$message({
            type: "success",
            message: "发送测试邮件成功！",
          });
          this.$emit("refresh");
        });
      });
    },
  },
};
</script>
